const idleCallback =
  (typeof self !== 'undefined' &&
    self.requestIdleCallback &&
    self.requestIdleCallback.bind(window)) ||
  function (cb: Function) {
    cb();
  };

export const loadLazyScript = <T extends Function>(scriptFn: T) => {
  if (typeof document === 'undefined') {
    return;
  }
  if (document.readyState === 'complete') {
    scriptFn();
  } else {
    window.addEventListener('load', () => {
      idleCallback(() => scriptFn());
    });
  }
};
