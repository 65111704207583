import { type WizardRouteOption } from '@strapi-schema';
import { groupBy } from 'lodash';
import {
  CreatePaymentsSessionResponse,
  Customer,
  PaymentDetailsInput,
  Questionnaire,
  QuestionnaireCollectedDataItem,
} from '@wearemotivated/backend-sdk';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { PageModel, SurveyModel } from '@wearemotivated/wizard-client/Wizard';
import { z } from 'zod';
import {
  decryptData,
  encryptData,
} from '@/app/evaluation/[...routeId]/utils-crypto';
import { loadLazyScript } from '@/app/utils/browser';
import { SurveyData } from '@/app/evaluation/[...routeId]/types';
import { getCookie, LANDING_PAGE_COOKIE_NAME } from '@/app/utils/auth';
import {
  SAVE_KEY_PREFIX,
  SURVEY_SAVED_ID_COOKIE,
  WIZARD_PATH,
} from '@/redesign/lib/constants';

export const REGISTRATION_PAGE_NAME = 'registration';
export const OTP_REGISTRATION_PAGE_NAME = 'passwordless-registration';
export const OTP_VERIFY_PAGE_NAME = 'otp-verify-page';
export const LOGIN_PAGE_NAME = 'login';
export const OTP_LOGIN_PAGE_NAME = 'passwordless-login';

export const DEFAULT_HOMEPAGE = '/evaluation/ed';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const valueToString = (value: unknown) => {
  if (typeof value === 'string') return value;
  return JSON.stringify(value);
};

const getWizardByWeight = (
  items: WizardRouteOption[],
  wizardRouteId: string,
  treatmentId: string,
): WizardRouteOption => {
  // retrieves saved choice if available
  const savedSelectedId = getWizardRouteAB({ wizardRouteId, treatmentId });
  if (savedSelectedId) {
    const found = items.find(
      (item) =>
        item.wizard?.data.id && String(item.wizard.data.id) === savedSelectedId,
    );

    if (found) {
      return found;
    }
  }

  const totalWeight = items.reduce((sum, item) => sum + (item.weight || 0), 0);
  let randomNumber = Math.random() * totalWeight;

  for (const item of items) {
    const weight = item.weight || 0;
    if (randomNumber < weight) {
      saveWizardRouteAB({
        wizardRouteId,
        treatmentId,
        surveyId: String(item.wizard?.data.id),
      });
      return item;
    }
    randomNumber -= weight;
  }

  return items[items.length - 1];
};

export function isNullOrWhitespace(input: string): boolean {
  return !input || input.trim().length === 0;
}

export const formatWizardItems = (
  items: WizardRouteOption[],
  wizardRouteId: string,
) => {
  const filteredItems = items.filter((item) => !!item.wizard);
  if (filteredItems.length < 2) return filteredItems;

  const groupedItems = groupBy(
    filteredItems,
    (item) =>
      item.wizard?.data?.attributes?.treatment?.data?.id ||
      item.wizard?.data?.id,
  );

  return Object.entries(groupedItems).map(([treatmentId, group]) => {
    return getWizardByWeight(group, wizardRouteId, treatmentId);
  });
};

// TODO response handling of the fetch function below can be abstracted

export async function addOrUpdateCustomerDocument(
  documentId: string,
  value: string,
  attemptsCount: number,
) {
  let res;
  try {
    res = await fetch('/api/user/survey', {
      method: 'POST',
      body: JSON.stringify({
        queryId: 'ADD_OR_UPDATE_CUSTOMER_DOCUMENT',
        documentId,
        value,
        attemptsCount,
      }),
    });
  } catch (e) {
    console.error('addOrUpdateCustomerDocument error', e);
    return;
  }

  if (!res.ok) {
    return;
  }

  try {
    return await res.json();
  } catch (e) {
    console.error('addOrUpdateCustomerDocument parse error', e);
  }

  // null means request succeeded but no data returned
  // undefined means request failed
  return null;
}

export async function createNewQuestionnaire(
  wizardId: string,
  questionnaireId = 'mock',
  metadataAttributes = [
    {
      key: '',
      value: '',
    },
  ],
) {
  let res;
  try {
    res = await fetch('/api/user/survey', {
      method: 'POST',
      body: JSON.stringify({
        queryId: 'CREATE_NEW_QUESTIONNAIRE',
        wizardId,
        questionnaireId,
        // TODO: remove after backend fix
        metadataAttributes,
      }),
    });
  } catch (e) {
    return;
  }

  if (!res.ok) {
    return;
  }

  try {
    const json = await res.json();
    return json;
  } catch (e) {
    console.error('createNewQuestionnaire parse error', e);
  }

  // null means request succeeded but no data returned
  // undefined means request failed
  return null;
}

export function convertQuestionObjectToCollectedDataItem(
  questionName: string,
  question: any,
  questionValue: any,
): QuestionnaireCollectedDataItem | undefined {
  const questionType = question?.getType?.() || 'other';

  if (questionName.includes('password')) {
    return;
  }

  let fieldValue = questionValue;

  if (fieldValue == 'none') fieldValue = question.noneItemValue.text;

  if (Array.isArray(fieldValue)) {
    fieldValue = fieldValue.filter((p) => p != 'other').join(',');
  }

  //TODO: Check storeOthersAsComment option
  if (question.comment) {
    if (fieldValue != '') fieldValue += ', ';

    fieldValue += `${question.otherText}: ${question.comment}`;
  }

  let choices = question.choices
    ? question.choices.map((choice: any) => choice.text)
    : [];

  if (question.hasOther) {
    choices.push(question.otherText);
  }

  if (question.hasNone) {
    choices.push(question.noneItemValue.text);
  }

  if (typeof fieldValue != 'string') {
    fieldValue = JSON.stringify(fieldValue);
  }

  // TODO remove this - converts dates to MMDDYYYY
  if (questionName === 'birthday' && /^\d{8}$/.test(String(fieldValue))) {
    const parsed = dayjs.utc(String(fieldValue), 'MMDDYYYY', true);
    if (parsed.isValid()) {
      fieldValue = parsed
        .hour(12)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toISOString();
    }
  }

  // TODO remove this - leaves only numbers in phone number
  if (questionName === 'phoneNumber') {
    fieldValue = fieldValue.replace(/\D+/g, '');
  }

  return {
    key: questionName,
    value: fieldValue,
    possibleOptions: choices,
    questionText: question.title,
    questionType: questionType,
  };
}

export async function bulkAddCollectedDataToQuestionnaire(
  questionnaireId: string,
  data: QuestionnaireCollectedDataItem[],
  magicToken?: string | null,
) {
  let res;
  try {
    res = await fetch('/api/user/survey', {
      method: 'POST',
      body: JSON.stringify({
        queryId: 'BULK_ADD_COLLECTED_DATA_TO_QUESTIONNAIRE',
        questionnaireId,
        data,
        magicToken,
      }),
    });
  } catch (e) {
    console.error('bulkAddCollectedDataToQuestionnaire error', e);
    return;
  }

  if (!res.ok) {
    console.error('bulkAddCollectedDataToQuestionnaire not ok', res.statusText);
    return;
  }

  try {
    const json = await res.json();
    return json;
  } catch (e) {
    console.error('bulkAddCollectedDataToQuestionnaire parse error', e);
  }

  // null means request succeeded but no data returned
  // undefined means request failed
  return null;
}

export async function reportQuestionnaireMilestone(
  questionnaireId: string,
  milestoneId: string,
  magicToken?: string | null,
) {
  let res;
  try {
    res = await fetch('/api/user/survey', {
      method: 'POST',
      body: JSON.stringify({
        queryId: 'REPORT_QUESTIONNAIRE_MILESTONE',
        questionnaireId,
        milestoneId,
        magicToken,
      }),
    });
  } catch (e) {
    return;
  }

  if (!res.ok) {
    return;
  }

  try {
    const json = await res.json();
    if (json?.success) {
      return json;
    }
  } catch (e) {
    console.error('reportQuestionnaireMilestone parse error', e);
  }

  // null means request succeeded but no data returned
  // undefined means request failed
  return null;
}

/** careful, it throws */
export async function createNewSubscription(
  shippingFrequencyId: string,
  questionnaire: Questionnaire,
  paymentDetails: PaymentDetailsInput,
  magicToken?: string,
) {
  const res = await fetch('/api/user/survey', {
    method: 'POST',
    body: JSON.stringify({
      queryId: 'CREATE_NEW_SUBSCRIPTION',
      shippingFrequencyId,
      questionnaire,
      paymentDetails,
      magicToken,
    }),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const json = await res.json();

  return json;
}

export const runScript = (script: string) => {
  try {
    const scriptFunction = new Function(script);
    loadLazyScript(async () => scriptFunction());
  } catch (e) {
    console.error('Script failed: ', script);
  }
};

export const getWizardRouteWithPage = (
  wizardRouteId: string,
  pageNumber?: number | null,
) => {
  if (!pageNumber) return `/evaluation/${wizardRouteId}`;
  return `/evaluation/${wizardRouteId}?page=${pageNumber}`;
};

export const getUploadedDocuments = async (): Promise<
  Customer | undefined | null
> => {
  let res;
  const queryId = 'GET_MY_UPLOADED';
  try {
    res = await fetch('/api/user/me', {
      method: 'POST',
      body: JSON.stringify({ queryId }),
    });
  } catch (e) {
    return;
  }

  if (!res.ok) {
    return;
  }

  try {
    return res.json();
  } catch (e) {
    console.error('getUploadedDocuments error', e);
    return null;
  }
};

const CHECKOUT_PAGE_NAME = 'checkout';
const CHECKOUT_QUESTION_NAME = 'checkout';
const KICKOUT_PAGE_NAME = 'kickout';

// builds url for a survey given its id and route id
export const buildWizardUrl = {
  survey: ({
    wizardId,
    wizardRouteId,
    hasMultipleSurveys,
  }: {
    wizardId?: number;
    wizardRouteId: string;
    hasMultipleSurveys?: boolean;
  }) => {
    return `${WIZARD_PATH}${wizardRouteId}${hasMultipleSurveys && wizardId !== undefined ? `/${wizardId}` : ''}`;
  },
};

// currentPageNo returns index based on currently visible pages
// thus it's not stable and can change as pages are shown/hidden
// this returns "real" index of the current page based on all survey pages
export const getCurrentIndex = (survey: SurveyModel) => {
  return survey.pages.indexOf(survey.currentPage);
};

export const getCheckoutIndex = (survey: SurveyModel) => {
  return survey.pages.indexOf(survey.getPageByName(CHECKOUT_PAGE_NAME));
};

// sanity check to make sure we can use localStorage
const isLocalStorageAvailable = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    const testItem = '__storage_test__';
    window.localStorage.setItem(testItem, testItem);
    window.localStorage.removeItem(testItem);
    return true;
  } catch (e) {
    return false;
  }
};

// sanity check to make sure we can use sessionStorage
const isSessionStorageAvailable = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    const testItem = '__storage_test__';
    sessionStorage.setItem(testItem, testItem);
    sessionStorage.removeItem(testItem);
    return true;
  } catch (e) {
    return false;
  }
};

const AB_KEY_PREFIX = 'motivated:uses_';
// one week
const SAVED_DATA_TTL = 30 * 24 * 60 * 60 * 1000;
export const SPECIAL_PAGE_NAMES = [
  LOGIN_PAGE_NAME,
  REGISTRATION_PAGE_NAME,
  OTP_LOGIN_PAGE_NAME,
  OTP_REGISTRATION_PAGE_NAME,
  OTP_VERIFY_PAGE_NAME,
];

type PageType = 'login' | 'checkout' | 'kickout';

export const isSurveyPage = ({
  name,
  types,
}: {
  name: string;
  types: PageType[];
}) => {
  if (!types.length) {
    return false;
  }

  switch (true) {
    case types.includes('checkout') && name === CHECKOUT_PAGE_NAME: {
      return true;
    }
    case types.includes('login') && SPECIAL_PAGE_NAMES.includes(name): {
      return true;
    }
    case types.includes('kickout') && name.startsWith('kickout'): {
      return true;
    }
  }

  return false;
};

export const isPageSpecial = ({
  name,
  allowRegPages,
  includeCheckout,
}: {
  name: string;
  allowRegPages?: boolean;
  includeCheckout?: boolean;
}) => {
  if (name === CHECKOUT_PAGE_NAME && includeCheckout) {
    return true;
  }

  if (!allowRegPages && SPECIAL_PAGE_NAMES.includes(name)) {
    return true;
  }

  // TODO do we need to check for kickout pages?
  if (name.startsWith('kickout')) {
    return true;
  }
  return false;
};

const savedDataSchema = z
  .object({
    currentIndex: z.number().int().nonnegative(),
    highestPage: z.number().int().gte(-1),
    timestamp: z.number().int().nonnegative(),
    lastMilestoneId: z.string().optional(),
  })
  .and(
    z.union([
      z.object({
        checkoutComplete: z.boolean(),
      }),
      z.object({
        data: z.record(z.any()),
      }),
    ]),
  );

type SavedDataType = z.infer<typeof savedDataSchema>;

// some fields should not be saved
const isFieldSaved = (fieldName: string) => {
  if (fieldName.endsWith('password')) {
    return false;
  }

  // image fields
  if (['id', 'face'].includes(fieldName)) {
    return false;
  }

  return true;
};

// save A/B choice for given route and treatment
export const saveWizardRouteAB = ({
  wizardRouteId,
  surveyId,
  treatmentId,
}: {
  wizardRouteId: string;
  treatmentId: string;
  surveyId: string;
}) => {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  window.localStorage.setItem(
    `${AB_KEY_PREFIX}${wizardRouteId}_${treatmentId}`,
    surveyId,
  );

  return true;
};

// retrieves A/B choice for given route and treatment
export const getWizardRouteAB = ({
  wizardRouteId,
  treatmentId,
}: {
  wizardRouteId: string;
  treatmentId: string;
}) => {
  if (!isLocalStorageAvailable()) {
    return null;
  }

  return window.localStorage.getItem(
    `${AB_KEY_PREFIX}${wizardRouteId}_${treatmentId}`,
  );
};

// clears saved A/B choice for the given route
export const clearWizardRouteAB = ({
  wizardRouteId,
}: {
  wizardRouteId: string;
}) => {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  for (const key in localStorage) {
    if (key.startsWith(`${AB_KEY_PREFIX}${wizardRouteId}_`)) {
      localStorage.removeItem(key);
    }
  }
  return true;
};

export const saveSurveyState = ({
  survey,
  surveyId,
  highestPage,
  op,
  lastMilestoneId,
  wizardRouteId,
}: {
  surveyId: string;
  survey: SurveyModel;
  highestPage: number;
  lastMilestoneId?: string;
  op?: 'after_checkout';
  // survey path slug
  wizardRouteId: string;
}): boolean | undefined => {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  if (process.env.NEXT_PUBLIC_FLAG_DEBUG_WIZARD === '1') {
    console.log('SAVE', { surveyId, highestPage });
  }

  // not saving survey state after checkout
  if (op === 'after_checkout') {
    const saveData: SavedDataType = {
      currentIndex: getCurrentIndex(survey),
      timestamp: Date.now(),
      highestPage,
      checkoutComplete: true,
      lastMilestoneId: lastMilestoneId,
    };

    window.localStorage.setItem(
      `${SAVE_KEY_PREFIX}${surveyId}`,
      encryptData(JSON.stringify(saveData)),
    );

    return true;
  }

  // saves survey slug to a cookie
  saveLastSurveyPath(wizardRouteId);

  // removes passwords when saving state
  // (we can add other sensitive data here)
  const dataSafe = Object.entries(survey.data).reduce(
    (acc, [key, value]) => {
      if (isFieldSaved(key)) {
        acc[key] = value;
      }
      return acc;
    },
    {} as typeof survey.data,
  );

  const saveData: SavedDataType = {
    data: dataSafe,
    currentIndex: getCurrentIndex(survey),
    timestamp: Date.now(),
    highestPage,
  };

  window.localStorage.setItem(
    `${SAVE_KEY_PREFIX}${surveyId}`,
    encryptData(JSON.stringify(saveData)),
  );

  return true;
};

const CLEAR_ALL = '__all__';

// also clears a/b choice if CLEAR_ALL is used
export const clearSurveyState = ({
  surveyId,
}: {
  surveyId: string | typeof CLEAR_ALL;
}) => {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  // clears last survey path in cookie
  clearLastSurveyPath();

  if (surveyId === CLEAR_ALL) {
    for (const key in localStorage) {
      if (key.startsWith(SAVE_KEY_PREFIX) || key.startsWith(AB_KEY_PREFIX)) {
        localStorage.removeItem(key);
      }
    }
    return true;
  }

  window.localStorage.removeItem(`${SAVE_KEY_PREFIX}${surveyId}`);
  return true;
};

export const clearAllSurveyState = () =>
  clearSurveyState({ surveyId: CLEAR_ALL });

// product questions and properties that need to be set when restoring
const PRODUCT_QUESTIONS_ORDERED = [
  {
    name: 'selected-category',
    query: 'categoryQuestionName',
    setName: 'categoryId',
  },
  { name: 'selected-drug', query: 'productQuestionName', setName: 'productId' },
  { name: 'selected-dosage', query: 'dosageQuestionName', setName: 'dosageId' },
  {
    name: 'selected-quantity',
    query: 'quantityQuestionName',
    setName: 'quantityId',
  },
] as const;

export const restoreProductData = ({ survey }: { survey: SurveyModel }) => {
  // product questions need their properties set when restoring
  const allQuestions = survey.getAllQuestions();
  PRODUCT_QUESTIONS_ORDERED.forEach(({ name, query, setName }) => {
    // this is copied from wizard-client
    // TODO product-select
    // refactor all product select questions
    const answered = survey.data[name];
    if (answered) {
      const setValue = answered?.value;

      const dependantQuestions = allQuestions.filter(
        (question) => question.getPropertyValue(query) === name,
      );

      dependantQuestions.forEach((question) => {
        question.setPropertyValue(setName, setValue);
      });
    }
  });
};

// if we are at certain page already we can pass currentPageIndex
// to avoid changing pages
export const restoreSurveyState = ({
  survey,
  surveyId,
  currentPageIndex,
  updateRefs,
}: {
  surveyId: string;
  survey: SurveyModel;
  currentPageIndex?: number | null;
  updateRefs?: (params: {
    newHighest: number;
    newCheckoutComplete: boolean;
  }) => void;
}) => {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  if (process.env.NEXT_PUBLIC_FLAG_DEBUG_WIZARD === '1') {
    console.log('RESTORE', { surveyId, currentPageIndex });
  }

  const key = `${SAVE_KEY_PREFIX}${surveyId}`;

  const stored = window.localStorage.getItem(key);
  if (!stored) {
    return false;
  }

  try {
    const decrypted = decryptData(stored);
    if (decrypted === null) {
      return false;
    }

    const parsed = JSON.parse(decrypted);
    const savedData = savedDataSchema.parse(parsed);

    // checks expiry
    if (Date.now() > savedData.timestamp + SAVED_DATA_TTL) {
      window.localStorage.removeItem(key);
      return false;
    }

    if ('data' in savedData) {
      survey.data = savedData.data;
    }

    // restores convoluted data model in product questions
    restoreProductData({ survey });

    const checkoutComplete =
      'checkoutComplete' in savedData && savedData.checkoutComplete;
    // if we don't have current page index -> restore from the saved state
    const restoreCurrentPage = typeof currentPageIndex !== 'number';

    // simply use saved index and return if checkout hasn't completed
    if (restoreCurrentPage && !checkoutComplete) {
      // saved data is validated by surveyjs
      survey.currentPage =
        savedData.highestPage > 0
          ? survey.pages[savedData.highestPage]
          : survey.pages[savedData.currentIndex];

      updateRefs?.({
        newHighest: savedData.highestPage,
        newCheckoutComplete: checkoutComplete,
      });

      return true;
    }

    // if checkout completed we validate page index before setting it
    // TS for some reason doesn't understand that restoreCurrentPage is type guard
    // restoreCurrentPage
    if (typeof currentPageIndex !== 'number') {
      currentPageIndex =
        savedData.highestPage > 0
          ? savedData.highestPage
          : savedData.currentIndex;
    }

    updateRefs?.({
      newHighest: savedData.highestPage,
      newCheckoutComplete: checkoutComplete,
    });

    // if we passed the current page index - use it instead of the saved one
    // but validate it against the saved data
    const pageIndex = validPageIndex({
      survey,
      pageIndex: currentPageIndex,
      highestPage: savedData.highestPage,
      checkoutComplete,
    });
    survey.currentPage = survey.pages[pageIndex];

    return {
      savedHighestPage: savedData.highestPage,
      savedPageIndex: savedData.currentIndex,
      validPageIndex: pageIndex,
      checkoutComplete,
    };
  } catch (e) {
    console.error('error while restoring survey state', e);
    return false;
  }
};

// checks that the page is visible
// and not one of the special pages like login
// if not valid then returns the closest previous page index that is valid
// if checkout is complete can't go to checkout or below
export const validPageIndex = ({
  survey,
  pageIndex,
  highestPage,
  checkoutComplete,
}: {
  survey: SurveyModel;
  pageIndex: number;
  highestPage?: number;
  checkoutComplete?: boolean;
}) => {
  let startIndex = pageIndex;
  if (startIndex >= survey.pages.length) {
    startIndex = survey.pages.length - 1;
  }

  const checkoutIndex = getCheckoutIndex(survey);
  // it combines two values - checkout page index and whether checkout is complete
  // false means checkout hasn't completed
  const minPageIndex =
    checkoutComplete && checkoutIndex > -1 ? checkoutIndex : false;

  const doIndexCheck = (validIndex: number) => {
    const page = survey.pages[validIndex];
    const highestOk =
      typeof highestPage !== 'number' ||
      (typeof highestPage === 'number' && validIndex <= highestPage);
    if (
      page.isVisible &&
      !isPageSpecial({
        name: page.name,
        // can return reg page if it's the current page
        allowRegPages: validIndex === startIndex,
      }) &&
      highestOk
    ) {
      if (process.env.NEXT_PUBLIC_FLAG_DEBUG_WIZARD === '1') {
        console.log('validPageIndex returned', {
          pageName: page.name,
          highestOk,
          highestPage,
          validIndex,
          checkoutComplete,
          vard_loggedin: survey.getVariable('vard_loggedin'),
        });
      }

      return validIndex;
    }
  };

  // regular check going down
  for (let validIndex = startIndex; validIndex > -1; validIndex--) {
    // bail if checkout is complete and we reached checkout page
    if (minPageIndex !== false && validIndex <= minPageIndex) {
      break;
    }

    const checkResult = doIndexCheck(validIndex);
    if (checkResult !== undefined) {
      return checkResult;
    }
  }

  // go up if we didn't find any valid page and checkout is complete
  if (minPageIndex !== false) {
    for (
      let validIndex = minPageIndex + 1;
      validIndex < survey.pages.length;
      validIndex++
    ) {
      const checkResult = doIndexCheck(validIndex);
      if (checkResult !== undefined) {
        return checkResult;
      }
    }
  }

  // if no valid page found
  // returns current page index
  return survey.pages.indexOf(survey.currentPage);
};

export const canGoBack = ({
  survey,
  checkoutComplete,
}: {
  survey: SurveyModel;
  /* not used currently */
  checkoutComplete?: boolean;
}) => {
  if (survey.currentPageNo === 0) {
    return false;
  }

  if (survey.currentPage?.name === CHECKOUT_PAGE_NAME) {
    const question = survey.getQuestionByName(CHECKOUT_QUESTION_NAME);
    if (question && question.hideBackButton) {
      return false;
    }
  }

  // checking for checkout complete is not needed as we shouldn't go back to checkout ever
  const prevPage = survey.visiblePages[survey.currentPageNo - 1];
  if (
    prevPage?.name === CHECKOUT_PAGE_NAME ||
    prevPage?.name === KICKOUT_PAGE_NAME
  ) {
    return false;
  }

  return true;
};

export const prepareQuestionnaire = ({
  survey,
  surveyData,
  wizardId,
  wizardRouteId,
  page,
  lastMilestoneId,
}: {
  surveyData: Partial<SurveyData>;
  survey: SurveyModel;
  wizardId: string;
  wizardRouteId: string;
  page: PageModel;
  lastMilestoneId?: string;
}) => {
  const landingPage = getCookie(LANDING_PAGE_COOKIE_NAME);

  return {
    wizardId,
    id: '1',
    data: Object.keys(surveyData)
      .map((questionName) => {
        const question = survey.getQuestionByName(questionName);

        if (question == null) {
          return;
        }

        return convertQuestionObjectToCollectedDataItem(
          questionName,
          question,
          surveyData[questionName],
        );
      })
      .filter((d) => d != null) as Array<QuestionnaireCollectedDataItem>,
    metadataAttributes: [
      { key: 'wizardId', value: wizardId },
      { key: 'wizardRouteId', value: wizardRouteId },
      { key: 'lastMilestoneId', value: lastMilestoneId || '' },
      { key: 'currentPage', value: page ? page.name : '' },
      { key: 'landingPage', value: landingPage ? atob(landingPage) : '' },
    ],
  };
};

export async function saveQuestionnaire(
  questionnaire: Questionnaire,
  magicToken?: string | null,
) {
  const res = await fetch('/api/user/survey', {
    method: 'POST',
    body: JSON.stringify({
      queryId: 'SAVE_QUESTIONNAIRE',
      questionnaire,
      magicToken,
    }),
  });

  if (!res.ok) {
    return null;
  }

  try {
    const json = await res.json();

    return json;
  } catch (e) {
    console.error('saveQuestionnaire error', e);
    return null;
  }
}

export async function fetchProducts(params: {
  categoryId: string;
  questionnaireContext?: any;
  magicToken?: string | null;
}) {
  const res = await fetch('/api/user/survey', {
    method: 'POST',
    body: JSON.stringify({
      queryId: 'FETCH_PRODUCTS',
      ...params,
    }),
  });

  if (!res.ok) {
    return null;
  }

  try {
    const json = await res.json();

    return json;
  } catch (e) {
    console.error('fetchProducts error', e);
    return null;
  }
}

export const saveQuestionnaireIfNeeded = async ({
  survey,
  surveyData,
  wizardRouteId,
  wizardId,
  page,
  lastMilestoneId,
  magicToken,
}: {
  surveyData: Partial<SurveyData>;
  survey: SurveyModel;
  wizardRouteId: string;
  wizardId: string;
  lastMilestoneId?: string;
  page: PageModel;
  magicToken?: string | null;
}) => {
  const doSave = page.getPropertyValue('saveQuestionnaire');
  if (!doSave) {
    return;
  }

  const questionnaire = prepareQuestionnaire({
    survey,
    surveyData,
    wizardId,
    wizardRouteId,
    page,
    lastMilestoneId,
  });
  return saveQuestionnaire(questionnaire, magicToken);
};

export const setSessionStorageItem = (key: string, value: any) => {
  console.log('setting session storage, key=' + key);
  if (isSessionStorageAvailable()) {
    sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    console.error('session storage not available, key=' + key);
  }
};

export const clearSessionStorageItem = (key: string) => {
  console.log('clear session storage, key=' + key);

  if (isSessionStorageAvailable()) {
    sessionStorage.removeItem(key);
  } else {
    console.error('session storage not available, key=' + key);
  }
};

/**
 * Gets a value from sessionStorage given a key and optionally clears it.
 * @param {string} key - The key under which the value is stored.
 * @param {boolean} clearAfterGet - Whether to clear the value after getting it.
 * @returns {any | null} - The parsed value from sessionStorage, or null if not available or parsing fails.
 */
export const getSessionStorageItem = (
  key: string,
  clearAfterGet: boolean = false,
): any | null => {
  if (!isSessionStorageAvailable()) {
    console.error('session storage not available, key=' + key);
    return null;
  }

  const item = sessionStorage.getItem(key);
  if (!item) {
    return null;
  }

  try {
    const parsedItem = JSON.parse(item);
    if (clearAfterGet) {
      sessionStorage.removeItem(key);
    }
    return parsedItem;
  } catch (e) {
    console.error('error parsing session storage item, key=' + key, e);
    return null;
  }
};

const PAYPAL_SESSION_KEY = 'motivated:paypal_session';
const PAYPAL_CALLBACK_DATA_KEY = 'motivated:paypal_cb_data';

export type PaypalSessionType = {
  url: string;
  response: CreatePaymentsSessionResponse;
  shippingFrequencyId: string;
  displayName: string;
  surveyState: {
    checkoutData: any;
    surveyData: any;
    wizardId: number;
    wizardRouteId: string;
  };
};

export type PaypalCallbackDataType = PaypalSessionType & {
  paypalResponse: {
    orderId: string;
    sessionId: string;
    status: string;
  };
};

export const savePaypalSession = (data: any) => {
  setSessionStorageItem(PAYPAL_SESSION_KEY, data);
};

// clears the value after getting so can be executed only once
export const getPaypalSession = () => {
  return getSessionStorageItem(PAYPAL_SESSION_KEY /* , true */);
};

export const clearPaypalSession = () => {
  clearSessionStorageItem(PAYPAL_SESSION_KEY);
};

export const savePaypalCallbackData = (data: any) => {
  console.log(
    'saving paypal callback data, PAYPAL_CALLBACK_DATA_KEY=' +
      PAYPAL_CALLBACK_DATA_KEY,
  );
  setSessionStorageItem(PAYPAL_CALLBACK_DATA_KEY, data);
};

// clears the value after getting so can be executed only once
export const getPaypalCallbackData = () => {
  return getSessionStorageItem(PAYPAL_CALLBACK_DATA_KEY /* , true */);
};

export const clearPaypalCallbackData = () => {
  clearSessionStorageItem(PAYPAL_CALLBACK_DATA_KEY);
};

export function setCookie(name: string, value: string, days: number = 400) {
  const maxAge = Math.round(days * 24 * 60 * 60);
  document.cookie = `${name}=${value}; max-age=${maxAge}; path=/`;
}

export function clearCookie(name: string) {
  document.cookie = `${name}=; max-age=0; path=/`;
}

export const getLastSurveyPath = () => {
  return getCookie(SURVEY_SAVED_ID_COOKIE);
};

export const saveLastSurveyPath = (surveyId: string) => {
  setCookie(SURVEY_SAVED_ID_COOKIE, surveyId);
};

export const clearLastSurveyPath = () => {
  return clearCookie(SURVEY_SAVED_ID_COOKIE);
};

/**
 * for legacy apps we check whether we have any saved survey data in localstorage
 */
export const getSavedSurveyPath = () => {
  if (!isLocalStorageAvailable()) {
    return false;
  }

  // we do not expect users to have more than one saved survey so we get the first one that matches
  for (const key in localStorage) {
    if (key.startsWith(SAVE_KEY_PREFIX)) {
      const match = key.match(/_(.*?)_/);
      return match ? match[1] : null;
    }
  }

  return null;
};

export const reportGtmEvent = (eventName: string, eventData: any) => {
  console.log('GTM-ClientEvent', {
    eventName,
    eventData,
  });

  let windowContext = window as any;
  windowContext.dataLayer.push({
    event: eventName,
    ...eventData,
  });
};
