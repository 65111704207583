import { getCookie } from '@/app/utils/auth';
import CryptoJS from 'crypto-js';

const CRYPTO_KEY_COOKIE_NAME = 'motivated:wizard_secret';
// 30 days
const CRYPTO_KEY_COOKIE_MAX_AGE = 1000 * 60 * 60 * 24 * 30;

const getParentDomain = (hostname: string) => {
  const parts = hostname.split('.');
  return parts.length > 1 ? parts.slice(-2).join('.') : hostname;
};

const setKeyCookie = (value: string) => {
  const baseDomain = getParentDomain(window.location.hostname);
  const secure = window.location.protocol === 'https:' ? ' Secure;' : '';
  document.cookie = `${CRYPTO_KEY_COOKIE_NAME}=${encodeURIComponent(value)}; Max-Age=${CRYPTO_KEY_COOKIE_MAX_AGE}; Domain=${baseDomain}; Path=/;${secure}`;
};

const getKeyCookie = () => {
  const stored = getCookie(CRYPTO_KEY_COOKIE_NAME);
  return stored ? decodeURIComponent(stored) : null;
};

export const clearKeyCookie = () => {
  const baseDomain = getParentDomain(window.location.hostname);
  const secure = window.location.protocol === 'https:' ? ' Secure; ' : '';
  document.cookie = `${CRYPTO_KEY_COOKIE_NAME}=; Max-Age=0; Domain=${baseDomain}; Path=/;${secure}`;
};

const generateKey = () => {
  return CryptoJS.lib.WordArray.random(24).toString();
};

const getKey = () => {
  let secretKey = getKeyCookie();
  if (!secretKey) {
    secretKey = generateKey();
    setKeyCookie(secretKey);
  }
  return secretKey;
};

export const encryptData = (data: string) => {
  return CryptoJS.AES.encrypt(data, getKey()).toString();
};

export const decryptData = (data: string) => {
  const secretKey = getKeyCookie();
  if (!secretKey) {
    return null;
  }

  try {
    return CryptoJS.AES.decrypt(data, secretKey).toString(CryptoJS.enc.Utf8);
  } catch (e) {
    console.log('failed to decrypt', e);
    return null;
  }
};
